<template>
  <v-card :loading="modalButtonLoader" >
    <div class="card-header" style="padding: 0">
      <div class="product-area">
        <img :src="singItem.img" class="item-img" />
        <div class="product-info">
          <h3 :style="themColor">{{ singItem.name }}</h3>
          <p>{{ singItem.description }}</p>
          <p :style="themColor" v-if="singItem.price">
            <strong>Price :</strong>{{ formattedPrice(singItem.price) }}
          </p>
        </div>
      </div>
      <v-btn icon @click="$emit('close')" class="cros-btn">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-card-text :class="{ addonsListArea: !singItem.sizes.length > 0 }">
      <div v-if="singItem.sizes.length > 0" class="mb-4">
        <p class="sizes-label" v-if="!checkSizesSelected && !isProductSizeGram">
          Please Select a Size
        </p>
        <v-row>
          <v-col
            style="padding: 6px"
            v-if="!isProductSizeGram"
            v-for="(card, index) in singItem.sizes"
            :key="card.id"
            cols="4"
            sm="4"
            md="2"
          >
            <v-card
              :style="{
                'background-color': card.selected
                  ? vendorResturent.main_color
                  : '#fff',
                color: card.selected
                  ? vendorResturent.font_color
                  : vendorResturent.main_color,
                border: '1px solid ' + vendorResturent.main_color,
              }"
              :class="{
                'clickable-card': !card.selected,
              }"
              @click="sizezCardSelection(card, index)"
            >
              <v-card-text class="sizeNamePrice"
                ><strong>{{ card.item_size.name }}</strong>
                <strong>{{ formattedPrice(card.price) }} </strong></v-card-text
              >
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!-- sizes adons -->
      <!-- addon -->
      <div
        v-if="singItem.sizes.length > 0"
        v-for="(group, index) in sizes_addons"
        :key="index"
        class="addonGroup"
      >
        <v-card-text class="addOn-group-name">
          <h3 style="text-align: initial">{{ group.name }}</h3>
          <p
            :class="{
              textRed: !checkGroupMinMax(index, 'sizeGroupAddon', 'checkAlert'),
            }"
            style="text-align: initial"
          >
            Please choose minimum of {{ group.min }} and maximum of
            {{ group.max }}.
          </p>
        </v-card-text>
        <!-- <v-btn @click="">testing</v-btn> -->
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="6"
            xs="12"
            v-for="(adon, addonIndex) in group.addon"
            :key="adon.id"
            class="checkBoxArea"
          >
            <v-checkbox
            
              v-model="adon.addon.is_checked"
              :color="vendorResturent.main_color"
              @click="
                handleCheckboxClick(
                  index,
                  addonIndex,
                  'sizeGroupAddon',
                  adon.addon.is_checked
                )
              "
            >
              <template v-slot:label>
                <div class="checkBox-lable">
                  <div class="addonList">
                    <span class="addon-label">{{ adon.addon.name }}</span>
                    <span class="price-text"
                      >{{ formattedPrice((adon.price * adon.qty).toFixed(2)) }}</span
                    >
                  </div>
                  <div
                    style="margin-left: 10px"
                    v-if="adon.addon.is_checked && adon.price > 0"
                  >
                    <v-btn
                      :style="themColorWithFont"
                      class="addon-qty-btn"
                      icon
                      @click="addDecrement(index, addonIndex, 'sizeGroupAddon')"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <input
                      class="addon-count-box"
                      :value="adon.qty"
                      readonly
                      :disabled="true"
                    />
                    <v-btn
                      :style="themColorWithFont"
                      class="addon-qty-btn"
                      icon
                      @click="
                        addonIncrement(index, addonIndex, 'sizeGroupAddon')
                      "
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
      <!--end addon -->
      <!--end sizes adons -->
      <!-- addon -->
      <div v-else v-for="(group, index) in singItem.addon" :key="group" class="addonGroup">
        <v-card-text class="addOn-group-name">
          <h3 style="text-align: initial">
            {{ group.adon_group.addon_category.name }} <span v-if="singItem.tiffan_service == 1" style="color:red">({{getTotalSelectedQuantities(index)}})</span>
          </h3>
          <p
            :class="{
              textRed: !checkGroupMinMax(index, 'isGroupAddone', 'checkAlert'),
            }"
            style="text-align: initial"
          >
            Please choose minimum of
            {{ group.adon_group.addon_category.min }} and maximum of
            {{ group.adon_group.addon_category.max }}.
          </p>
        </v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="6"
            lg="4"
            xs="12"
            v-for="(adon, addonIndex) in group.addon"
            :key="adon.id"
            class="checkBoxArea"
          >
            <!-- :disabled="isAddonDisabled(index,'isGroupAddone') && !adon.addon.isSelect" -->
             <!-- :disabled="checkAddonSelection" -->
            <div style="display: flex">
              <v-checkbox
                v-model="adon.addon.isSelect"
                :color="vendorResturent.main_color"
                :disabled="(checkAddonSelection && singItem.tiffan_service === 1) && !adon.addon.isSelect || checkAddonMax(index, addonIndex,getTotalSelectedQuantities(index))"
                @click="
                  handleCheckboxClick(
                    index,
                    addonIndex,
                    'isGroupAddone',
                    adon.addon.isSelect
                  )
                "
              >
                <template v-slot:label>
                  <div class="checkBox-lable">
                    <div class="addonList">
                      <span class="addon-label">{{ adon.addon.name }}</span>
                      <span v-if="singItem.tiffan_service == 0" class="price-text"
                        >{{ formattedPrice(adon.qty * adon.price) }}</span
                      >
                    <span v-else style="font-size:11px;text-align:left">min:{{adon.addon.min}} ,max:{{adon.addon.max}}</span>
                     
                    </div>
                    <div
                      style="margin-left: 10px"
                      v-if="
                        adon.addon.isSelect
                      "
                    >
                      <v-btn
                        v-if="singItem.tiffan_service === 0"
                        :style="themColorWithFont"
                        class="addon-qty-btn"
                        icon
                        @click.prevent="
                          addDecrement(index, addonIndex, 'isGroupAddone')
                        "
                        :disabled="adon.addon.min >= adon.qty"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        :style="themColorWithFont"
                        class="addon-qty-btn"
                        icon
                        @click.prevent="
                          addDecrement(index, addonIndex, 'isGroupAddone')
                        "
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <input
                        class="addon-count-box"
                        :value="adon.qty"
                        readonly
                        :disabled="true"
                      />
                      <v-btn
                        v-if="singItem.tiffan_service === 0"
                        :style="themColorWithFont"
                        class="addon-qty-btn"
                        icon
                        :disabled="adon.addon.max === adon.qty"
                        @click.prevent="
                          addonIncrement(index, addonIndex, 'isGroupAddone')
                        "
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        :style="themColorWithFont"
                        class="addon-qty-btn"
                        icon
                        @click.prevent="
                          addonIncrement(index, addonIndex, 'isGroupAddone')
                        "
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </template>
              </v-checkbox>
            </div>
          </v-col>
        </v-row>
      </div>
    
      <!--end addon -->
      <v-divider class="divider-border"></v-divider>
      <div class="item-qty-section">
        <div class="left-qty">
          <div v-if="isProductSizeGram">
            <span> Add Grames</span>
            <input
              type="text"
              placeholder="Type Gram.."
              min="0.1"
              v-model="singItem.grameSize"
              class="gram-count-box"
              name="myInput"
              :style="{
                border: '1px solid ' + vendorResturent.main_color,
              }"
            />
            <span>{{ formattedPrice(grameTotalPrice) }}</span>
          </div>
          <strong v-else>QUANTITY</strong>
        </div>
        <div class="right-qty">
          <v-btn
            :style="themColorWithFont"
            class="qty-btn"
            icon
            @click="decrement"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <input
            :style="{
              border: '1px solid ' + vendorResturent.main_color,
            }"
            type="text"
            v-model="singItem.qty"
            readonly
            class="count-box"
            name="myInput"
          />
          <v-btn
            :style="themColorWithFont"
            class="qty-btn"
            icon
            @click="increment"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn class="modal-btn" @click="$emit('close')" text>Cancel</v-btn>
      <!-- <v-btn class="modal-btn" :style="themColorWithFont"  @click="checkAddonSelection" :loading="modalButtonLoader">Add To Cart</v-btn>  -->
      <v-btn
        class="modal-btn"
        style="margin-left: 0"
        :style="themColorWithFont"
        @click="addToCart"
        :disabled="!checkAddonSelection"
        :loading="modalButtonLoader"
        >Add To Cart</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { posStore } from "@/store/posStore";
export default {
  props: [
    "singItem",
    "themColorWithFont",
    "themColor",
    "vendorResturent",
    "modalButtonLoader",
    "formattedPrice"
  ],
  setup() {
    const usePOSStore = posStore();
    return { usePOSStore };
  },
  data() {
    return {
      dialogm1: "",
      incrementAudio: "audio/increment.mp3",
      sizes_addons: "",
    };
  },
  computed: {
    checkAddonSelection() {
      var breakLoop = true;
      if (this.singItem.sizes.length > 0) {
        if (this.sizes_addons.length > 0) {
          this.sizes_addons.forEach((item) => {
            if (breakLoop) {
              var count = 0;
              item.addon.forEach((addon) => {
                if (addon.addon.is_checked) {
                  count++;
                }
              });
              if (count < item.min) {
               
                breakLoop = false;
              }
            }
          });
        } else {
          breakLoop = true;
        }
      } else if (this.singItem.addon.length > 0) {
         
        this.singItem.addon.forEach((group, index) => {
          if (breakLoop) {
            var count = 0;
            group.addon.forEach((item) => {
              if (item.addon.isSelect) {
                 if(this.singItem.tiffan_service == 1){
                    count += item.qty;
                 }else{
                   count++;
                   console.log(count)
                 }
              }
            });
            
            const addonsGroup = group.adon_group.addon_category;
            console.log(addonsGroup)
            const { min } = addonsGroup;
            const { tiffan_service } = this.singItem;
            // && tiffan_service === 1
            if (count < min) {
              
              breakLoop = false;
            }else if(tiffan_service === 0){
              breakLoop = true;
            }
          }
        });
      } else {
        breakLoop = true;
      }
      console.log(breakLoop)
      return breakLoop;
    },
    checkSizesSelected() {
      if (!this.isProductSizeGram) {
        if (this.singItem.sizes.length > 0) {
          if (this.singItem.sizes.some((item) => item.selected)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        if (this.singItem.grameSize) {
          return true;
        } else {
          return false;
        }
      }
    },
    isProductSizeGram() {
      return this.singItem.sizes.some(
        (item) => item.item_size.name === "grams"
      );
    },
    grameTotalPrice() {
      return (this.singItem.grameSize * this.singItem.sizes[0].price).toFixed(
        2
      );
    },
  },
  mounted() {
    if (this.singItem.sizes.length > 0) {
      this.sizezCardSelection(this.singItem.sizes[0], 0);
      this.singItem.sizes[0].isSelect = true;
    }
  },
  methods: {
   checkAddonMax(groupIndex, addonIndex,totalQty){
      const group = this.singItem.addon[groupIndex];
      const addon = group.addon[addonIndex].addon;
    
      const qty = parseInt(totalQty) + parseInt(addon.min);
      if(group.adon_group.addon_category.max < qty && !addon.isSelect){
        return true
      }
         return false;

   },
    addonIncrement(groupIndex, addonIndex, status) {
      if (status == "isGroupAddone") {
        const addon = this.singItem.addon[groupIndex].addon[addonIndex];
        if(addon.qty < addon.addon.max){
          addon.qty++;
        }
         if(this.singItem.tiffan_service == 1){
           if(addon.qty < addon.addon.max){
             addon.qty+1;
           }
           this.checkAdonsQty(groupIndex,addonIndex,"buttonClick");
         }else{
           addon.qty++;
         }
      } else {
        this.sizes_addons[groupIndex].addon[addonIndex];
        this.sizes_addons[groupIndex].addon[addonIndex].qty++;
      }
    },
    addDecrement(groupIndex, addonIndex, status) {
      if (status == "isGroupAddone") {
         const addon = this.singItem.addon[groupIndex].addon[addonIndex];
        if (addon.qty > 1) {
          if(this.singItem.tiffan_service == 1){
            if(addon.addon.min < addon.qty){
               addon.qty--;
            }else{
              addon.qty = addon.addon.min;
            }
          }else{
            addon.qty--;
          }
          
          
        }
      } else {
        if (this.sizes_addons[groupIndex].addon[addonIndex].qty > 1) {
          this.sizes_addons[groupIndex].addon[addonIndex].qty--;
        }
      }
    },
    getTotalSelectedQuantities(groupIndex) {
        const group = this.singItem.addon[groupIndex];
        let totalQuantity = 0;

        group.addon.forEach((addon) => {
            if (addon.addon.isSelect) {
                totalQuantity += addon.qty;
            }
        });

        return totalQuantity;
      },
    checkAdonsQty(groupIndex, addonIndex, status) {
        const group = this.singItem.addon[groupIndex];
        const maxAllowed = group.adon_group.addon_category.max;
        const totalQuantity = this.getTotalSelectedQuantities(groupIndex);

            if (status === "buttonClick" && totalQuantity > maxAllowed) {
        group.addon.forEach((addon, index) => {
            if (index !== addonIndex) {
                addon.qty = 1;
                addon.addon.isSelect = false;
            }
        });
    } else if (status === "clickCheckBox" && totalQuantity >= maxAllowed) {
        // group.addon.forEach((addon, index) => {
        //     if (index !== addonIndex) {
        //         addon.qty = 1;
        //         addon.addon.isSelect = false;
        //     }
        // });
      }
    },
    handleCheckboxClick(groupIndex, addonIndex, status, checked) {
      if (status == "isGroupAddone") {
        const addon_min =
          this.singItem.addon[groupIndex].addon[addonIndex].addon.min;
          this.singItem.addon[groupIndex].addon[addonIndex].qty =
          addon_min > 0 ? addon_min : 1;   
           if(this.singItem.tiffan_service == 1){
              this.checkAdonsQty(groupIndex,addonIndex,"clickCheckBox");
            }
      } else {
        this.sizes_addons[groupIndex].addon[addonIndex].qty = 1;
      }
      if (
        this.checkGroupMinMax(groupIndex, status, "checkGoroupCondition") &&
        !checked
      ) {
        if (status == "isGroupAddone") {
          this.singItem.addon[groupIndex].addon.forEach((item, index) => {
            if (item.addon.isSelect) {
              this.singItem.addon[groupIndex].addon[
                index
              ].addon.isSelect = false;
              this.singItem.addon[groupIndex].addon[index].qty = 1;
              
            }
          });
        } else {
          this.sizes_addons[groupIndex].addon.forEach((item, index) => {
            this.sizes_addons[groupIndex].addon[index].addon.is_checked = false;
            this.sizes_addons[groupIndex].addon[index].qty = 1;
          });
        }
      }
      
    },
    checkGroupMinMax(groupIndex, status, group) {
      var selectedAddonCount = 0;
      if (status == "isGroupAddone") {
        var groups = this.singItem.addon[groupIndex].adon_group.addon_category;
        selectedAddonCount = this.singItem.addon[groupIndex].addon.filter(
          (item) => item.addon.isSelect
        ).length;
      } else {
        var groups = this.sizes_addons[groupIndex];
        selectedAddonCount = this.sizes_addons[groupIndex].addon.filter(
          (item) => item.addon.is_checked
        ).length;
      }
      if (group == "checkGoroupCondition") {
        return (
          selectedAddonCount >= groups.min && selectedAddonCount == groups.max
        );
      } else {
        return selectedAddonCount >= groups.min;
      }
    },
    addToCart() {
      if (this.isProductSizeGram) {
        this.singItem.sizes[0].price = this.grameTotalPrice;
      }
      var addons = [];
      if (this.singItem.addon.length > 0) {
        this.singItem.addon.forEach((item, n) => {
          item.addon.forEach((addon, index) => {
            if (addon.addon.isSelect) {
              this.singItem.addon[n].addon[index].addon.is_checked = true;
              let addonObj = {
                id: addon.addon.id,
                name: addon.addon.name,
                price: addon.price, 
                qty: addon.qty,
              };
              addons.push(addonObj);
            }
          });
        });
      }
      // this.usePOSStore.posStorage(this.singItem,this.sizes_addons);
      const arr = {
        items: this.singItem,
        sizes_addons: this.sizes_addons,
        addons: addons,
      };
      this.$emit("add-to-cart-action", arr);
    },
    increment() {
      // const audio = new Audio(this.incrementAudio);
      // audio.play();
      return this.singItem.qty++;
    },
    decrement() {
      if (this.singItem.qty > 1) {
        // const audio = new Audio(this.incrementAudio);
        // audio.play();
        return this.singItem.qty--;
      }
    },
    sizezCardSelection(data, index) {
      this.singItem.sizes.forEach((item) => {
        item.selected = false;
        item.menu_addon.forEach((addon) => {
          addon.addon.is_checked = false;
        });
      });
      this.singItem.sizes[index].selected = true;
      const menu_addon = data.menu_addon;

      this.sizes_addons = [];
      var sizes_group = [];
      menu_addon.forEach((menu) => {
        // menu.addon.is_checked = false;
        if (
          !sizes_group.find((item) => item.id === menu.addon.addon_category.id)
        ) {
          sizes_group.push({
            id: menu.addon.addon_category.id,
            name: menu.addon.addon_category.name,
            max: menu.addon.addon_category.max,
            min: menu.addon.addon_category.min,
            addon: [],
          });
        }
        const indx = sizes_group.findIndex(
          (item) => item.id === menu.addon.addon_category.id
        );
        menu.qty = 1;
        sizes_group[indx].addon.push(menu);
        this.sizes_addons = sizes_group;
      });
    },
  },
};
</script>

<style scoped>
.header {
  border-bottom: 1px solid #c2c9d0;
  padding: 15px !important;
}
.gram-count-box {
  width: 90px;
  padding: 10 5px;
  border: 1px solid #c2c9d0;

  border-radius: 5px;
  margin: 0 5px;
  text-align: center;
}
.addon-count-box {
  width: 20px;
  border-radius: 5px;
  text-align: center;
  height: 20px;
}
.count-box {
  width: 50px;
  padding: 0 5px;
  border: 1px solid #c2c9d0;
  border-radius: 5px;
  margin: 0 10px;
  text-align: center;
}
.addon-qty-btn {
  width: 18px;
  height: 18px;
  font-size: 12px;
}
.item-qty-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.qty-btn {
  width: 25px;
  height: 25px;
}
.product-area {
  display: flex;
}
.item-img {
  width: 125px;
  height: 125px;
  border-bottom-right-radius: 10px;
}
.product-area .product-info {
  padding: 5px 10px;
  width: 73%;
}

.v-card-text {
  display: grid !important;
  text-align: center !important;
  padding: 10px 0;
}

.modal-btn {
  width: 50%;
  border-radius: 0;
}
.sizes-label {
  text-align: initial;
  color: #d7171c;
  font-weight: 700;
}
.adon-check-label {
  display: grid;
  margin-top: 10px;
}
.price-text {
  font-size: 12px;
  text-align: start;
}
.addon-label {
  font-size: 14px;
  font-weight: 500;
}

.custom-color input[type="checkbox"] {
  background-color: #d7171c;
  border-color: #d7171c;
}
.checkBoxArea {
  padding: 0 !important;
  height: 60px;
}
.addonList {
  display: grid;
  margin-top: 10px;
}
.checkBox-lable {
  display: flex;
  align-items: center;
}
.cros-btn {
  position: absolute !important;
  top: 5px !important;
  right: 5px !important;
}
.addOn-group-name {
  margin: 10px 0;
}
.divider-border {
  margin-top: 20px;
}
.sizeNamePrice {
  font-size: 12px !important;
  padding: 5px;
}
@media (max-width: 768px) {
  .checkBoxArea {
    padding: 0 !important;
    height: 50px !important;
  }
}
@media (max-width: 400px) {
  .qty-btn {
    width: 18px;
    height: 18px;
    font-size: 13px;
  }
  .count-box {
    width: 35px;
  }
  .gram-count-box {
    width: 35px !important;
  }
}
@media (max-width: 599.99px) {
  .addOn-group-name {
    padding: 0 !important;
    margin-top: 0px;
  }
  .product-area .product-info {
    width: 67%;
  }
  .product-info h3 {
    font-size: 15px;
  }
  .product-info p {
    font-size: 12px;
  }
  .item-img {
    width: 100px !important;
    height: 100px !important;
  }
  .cros-btn {
    width: 30px;
    height: 30px;
  }
  .addonList {
    display: flex;
    margin-top: 0;
  }
  .addonList .price-text {
    margin-left: 7px;
  }

  .checkBoxArea {
    height: 40px !important;
  }
  .addonGroup {
    margin-bottom: 25px;
  }
  .checkBox-lable {
    justify-content: space-between;
    width: 100%;
    padding-right: 20px;
  }
  .item-qty-section span {
    font-size: 12px !important;
  }
  .gram-count-box {
    width: 45px;
  }
  .addonGroup p {
    font-size: 12px;
  }
  .divider-border {
    margin-top: 0;
  }
}
.textRed {
  color: red !important;
}
.addonsListArea {
  padding: 10px 20px 0px !important;
}
.v-card-actions {
  padding: 0 !important;
  min-height: 36px !important;
}
.v-card-actions .v-btn {
  padding: 0;
  height: 45px;
}
</style>
